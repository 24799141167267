import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import type { Person, StravaActivity } from 'core';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import {
  InfoFieldStack,
  InfoFieldView,
} from '../../components/info-field/info-field.js';
import { PoweredByStravaIcon } from '../../img/powered-by-strava.js';
import {
  formatDate,
  formatMeters,
  formatSeconds,
  formatTime,
} from '../../utils/format.js';

interface Props {
  data: StravaActivity[];
  onImportClick: (activity: StravaActivity) => void;
  onOnlyRunsChanged: () => void;
  onlyRuns: boolean;
  person: Person.Type;
}

export const ListItemsView: FC<Props> = ({
  data: items,
  onImportClick,
  onOnlyRunsChanged,
  onlyRuns,
}) => (
  <Stack spacing={3}>
    <Box display="flex" flex={1} justifyContent="space-between">
      <Typography variant="h3">Import Activity from Strava</Typography>
      <FormControlLabel
        control={<Switch checked={onlyRuns} onChange={onOnlyRunsChanged} />}
        label="Only Runs"
        labelPlacement="start"
      />
    </Box>
    {items.map((item) => (
      <Card key={item.stravaId} sx={{ width: '100%' }}>
        <CardContent>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            display="flex"
            flex={1}
            justifyContent="space-between"
          >
            <Stack spacing={1}>
              <Stack spacing={1} direction="row">
                <Typography variant="h3">{item.name}</Typography>
                {item.trackExists && (
                  <Chip
                    color="info"
                    label="Imported"
                    size="small"
                    sx={{ ml: '1rem' }}
                  />
                )}
              </Stack>
              <InfoFieldStack>
                <InfoFieldView label="Date">
                  {formatDate(item.start.when) ?? '-'}
                </InfoFieldView>
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                <InfoFieldView label="Start">
                  {formatTime(item.start.when) ?? '-'}
                </InfoFieldView>
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                <InfoFieldView label="Duration">
                  {formatSeconds(
                    DateTime.fromJSDate(item.finish.when).diff(
                      DateTime.fromJSDate(item.start.when),
                      'seconds',
                    ).seconds,
                  ) ?? '-'}
                </InfoFieldView>
                {item.distance ? (
                  <>
                    <Divider orientation="vertical" sx={{ height: '2rem' }} />
                    <InfoFieldView label="Distance">
                      {formatMeters(item.distance) ?? '-'}
                    </InfoFieldView>
                  </>
                ) : undefined}
              </InfoFieldStack>
            </Stack>
            <Stack spacing={1} direction="column">
              <PoweredByStravaIcon
                sx={{ alignSelf: 'end', height: 26, mt: { xs: 0.5, sm: 0 } }}
              />
              {!item.trackExists ? (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => onImportClick(item)}
                >
                  Import
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    ))}
  </Stack>
);
