import { ButtonBase, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import uri from 'uri-tag';
import { ConnectWithStravaIcon } from '../../img/connect-with-strava.js';

export const UnauthorizedView: FC = () => {
  const onAuthorizeClick = (): void => {
    const state = btoa(
      JSON.stringify({
        failure: '/#/strava/import',
        success: '/#/strava/import',
      }),
    );
    const redirectBase = location.origin;
    location.href = uri`https://www.strava.com/oauth/authorize?client_id=${
      import.meta.env.VITE_STRAVA_CLIENT_ID
    }&redirect_uri=${redirectBase}/api/v1/strava/callback&response_type=code&approval_prompt=auto&scope=activity:read_all&state=${state}`;
  };

  return (
    <Stack spacing={2}>
      <Typography variant="body2" color="text.secondary" fontStyle="italic">
        In order to import activities from Strava, you need to authorize OTrax
        to access your activities.
      </Typography>
      <ButtonBase
        onClick={onAuthorizeClick}
        sx={{ width: 'fit-content', alignSelf: 'center' }}
      >
        <ConnectWithStravaIcon />
      </ButtonBase>
    </Stack>
  );
};
