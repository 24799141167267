import { captureException } from '@sentry/react';
import axios from 'axios';
import type { StravaActivity, Track } from 'core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePromise from 'react-use-promise';
import uri from 'uri-tag';
import { useAuthenticated } from '../../auth/auth-provider.js';
import { useLocalStorage } from '../../hooks/use-local-storage.js';
import { StravaImportListView } from './view.js';

const Controller: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { person } = useAuthenticated();
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [onlyRuns, setOnlyRuns] = useLocalStorage(
    'strava-import-only-runs',
    true,
  );

  const activities = usePromise(async () => {
    const { data } = await axios.get<StravaActivity[]>(
      uri`/api/v1/strava/activities`,
      { params: { onlyRuns } },
    );
    return data;
  }, [onlyRuns]);

  const onImportClick = (activity: StravaActivity): void => {
    setBusy(true);
    axios
      .post<Track.Type>(
        uri`/api/v1/strava/activity/${activity.stravaId}/import`,
      )
      .then(({ data: track }) => {
        navigate(uri`/track/${track._id}`);
        return;
      })
      .finally(() => setBusy(false))
      .catch((err) => {
        enqueueSnackbar('Unable to import this activity', { variant: 'error' });
        captureException(err, {
          extra: { activity: 'strava-import-activity' },
        });
      });
  };

  return StravaImportListView({
    activities,
    busy,
    onImportClick,
    onOnlyRunsChanged: () => setOnlyRuns(!onlyRuns),
    onlyRuns,
    person,
  });
};

export default Controller;
