import {
  Box,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import type { FC } from 'react';

interface Props {
  onOnlyRunsChanged: () => void;
  onlyRuns: boolean;
}

export const NoItemsView: FC<Props> = ({ onOnlyRunsChanged, onlyRuns }) => (
  <Stack spacing={3}>
    <Box display="flex" flex={1} justifyContent="space-between">
      <Typography variant="h3">Import Activity from Strava</Typography>
      <FormControlLabel
        control={<Switch checked={onlyRuns} onChange={onOnlyRunsChanged} />}
        label="Only Runs"
        labelPlacement="start"
      />
    </Box>
    <Typography variant="body2" color="text.secondary" fontStyle="italic">
      No activities could be found on Strava.
    </Typography>
    {onlyRuns && (
      <Typography variant="body2" color="text.secondary">
        Try looking for activities that weren't created as "Runs" in Strave by
        adjusting the toggle.
      </Typography>
    )}
    <Typography variant="body2" color="text.secondary">
      Check that your activities are marked as visible to "Everyone" in Strava.
      <ul>
        <li>
          <Link href="https://support.strava.com/hc/en-us/articles/216919377">
            Learn More
          </Link>
        </li>
        <li>
          <Link href="https://www.strava.com/settings/privacy">
            Strava Privacy Settings
          </Link>
        </li>
      </ul>
    </Typography>
  </Stack>
);
