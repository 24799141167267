import { Statuses } from '@geee-be/core';
import { Container } from '@mui/material';
import { isAxiosError } from 'axios';
import type { Person, StravaActivity } from 'core';
import type { FC } from 'react';
import { ListPendingView } from '../../components/common/list-pending.js';
import { PageTitle } from '../../components/page-title/page-title.js';
import { MiddleSpinner } from '../../components/spinner/spinner.js';
import type { UsePromiseResult } from '../../utils/promise.js';
import { ListItemsView } from './list-items.js';
import { NoItemsView } from './no-items.js';
import { UnauthorizedView } from './unauthorized.js';

export interface PageProps {
  activities: UsePromiseResult<StravaActivity[]>;
  busy: boolean;
  onImportClick: (activity: StravaActivity) => void;
  onOnlyRunsChanged: () => void;
  onlyRuns: boolean;
  person: Person.Type;
}

export const StravaImportListView: FC<PageProps> = ({
  activities: [data, error, state],
  busy,
  onImportClick,
  onOnlyRunsChanged,
  onlyRuns,
  person,
}) => (
  <PageTitle title="Strava Import">
    <Container maxWidth="md" sx={{ my: 3 }}>
      {state === 'pending' ? (
        <ListPendingView />
      ) : state === 'rejected' ? (
        isAxiosError(error) && error.status === Statuses.UNAUTHORIZED ? (
          <UnauthorizedView />
        ) : (
          <UnauthorizedView />
          // <ErrorView error={error} /> TODO
        )
      ) : !data || !data.length ? (
        <NoItemsView
          onOnlyRunsChanged={onOnlyRunsChanged}
          onlyRuns={onlyRuns}
        />
      ) : busy ? (
        <MiddleSpinner />
      ) : (
        <ListItemsView
          data={data}
          onImportClick={onImportClick}
          onOnlyRunsChanged={onOnlyRunsChanged}
          onlyRuns={onlyRuns}
          person={person}
        />
      )}
    </Container>
  </PageTitle>
);
